.login {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-gray-color);
}

.login__container {
    width: 100%;
    max-width: 40rem;
    height: auto;
    display: flex;
    background: white;
    box-shadow: 0 0 .875rem rgba(28, 28, 28, .1);
    border-radius: .625rem;
}

.login__container h1 {
    font-size: 2rem;
    margin: 0 0 1rem;
    padding: 0;
}

.login__container__left {
    width: 45%;
    padding: 45px 35px;
    border-radius: .625rem 0 0 .625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--accent-color);
    -webkit-clip-path: polygon(98% 17%, 100% 34%, 98% 51%, 100% 68%, 98% 84%, 100% 100%, 0 100%, 0 0, 100% 0);
    clip-path: polygon(98% 17%, 100% 34%, 98% 51%, 100% 68%, 98% 84%, 100% 100%, 0 100%, 0 0, 100% 0);
}

.login__container__left img {
    width: 100%;
    height: auto;
}

.login__container__right {
    width: 55%;
    padding: 45px 35px;
    display: flex;
    flex-direction: column;
}

.login__container__form {
    display: flex;
    flex-direction: column;
}

.login__container__form > *:not(:last-child) {
    margin-bottom: 1rem;
}

.login__container__form input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    font-size: 16px;
    letter-spacing: 1px;
    outline: none;
    border: 1px solid var(--light-gray2-color);
    border-radius: 5px;
}

.login__container__form input:focus {
    border-color: var(--accent-alt-color);
}

.login__container__form button {
    display: inline-block;
    padding: 7px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
    border-radius: 5px;
    color: white;
    outline: none;
    border: 1px solid var(--accent-alt-color);
    transition: .3s;
    -webkit-transition: .3s;
    cursor: pointer;
    background-color: var(--accent-alt-color);
}

.login__container__form button:hover {
    color: var(--accent-alt-color);
    background: white;
}

.login__container__form button.btn__disabled {
    color: white;
    background: var(--light-gray2-color);
    border: 1px solid var(--light-gray2-color);
    cursor: not-allowed;
}

.login__container__form a {
    color: var(--accent-alt-color);
    text-decoration: none;
    font-size: .875rem;
}

.login__container__form a:hover {
    text-decoration: underline;
}
