.slide {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.slide:not(:last-child) {
    border-bottom: 1px dashed var(--muted-text-color);
    margin-bottom: 1rem;
}

.slide img {
    width: 100%;
    max-width: 20rem;
    height: auto;
}

.slideBody {
    margin-left: 2rem;
}

.slideBody input {
    width: 22rem;
    margin-bottom: .5rem;
}