.page {
    display: grid;
    grid-template-columns: 16rem 1fr;
    grid-template-rows: 3rem 1fr;
    gap: 0 0;
    grid-template-areas:
    "aside header"
    "aside content";
    height: inherit;
    width: 100%;
}

.page__content {
    grid-area: content;
    padding: 1rem;
}