textarea, input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    font-size: 16px;
    letter-spacing: 1px;
    outline: none;
    border: 1px solid var(--light-gray2-color);
    border-radius: 5px;
}

textarea {
    width: 100%;
    height: auto;
}

input:focus {
    border-color: var(--accent-alt-color);
}

.dropzone {
    border: 3px dashed var(--accent-alt-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropzone p {
    max-width: 20rem;
    text-align: center;
}

.dropzone.dragActive {
    border-style: solid;
}

.dropzone img {
    width: 4rem;
    height: auto;
    transition: transform .25s ease-in;
}
.dropzone.dragActive img {
    transform: scale(1.15);
}

.img {
    width: 100%;
    height: auto;
}

.checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.checkbox label {
    cursor: pointer;
}

.checkbox > * {
    width: auto;
}

.imagesGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    gap: 2rem;
    margin: 2rem 0;
}

.actionButton {
    border: none;
    background: transparent;

}

.actionButton img {
    width: 1rem;
    height: 1rem;
    border: 1px solid red;
}

.btn {
    display: inline-block;
    padding: 7px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
    border-radius: 5px;
    color: white;
    outline: none;
    border: 1px solid var(--accent-alt-color);
    transition: .3s;
    -webkit-transition: .3s;
    cursor: pointer;
    background-color: var(--accent-alt-color);
}

.btnRed {
    border: 1px solid tomato;
    background-color: tomato;
}

.btnGreen {
    border: 1px solid limegreen;
    background-color: limegreen;
}

.btnImage {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    -webkit-transition: .3s;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    text-decoration: none;
    border-radius: 5px;
    border: 1px solid var(--muted-text-color);
    padding: .25rem .5rem 0;
}

.btnImage img {
    height: 1.5rem;
    width: auto;
    margin: 0 auto;
    padding: 0;
}

.btn:hover {
    color: var(--accent-alt-color);
    background: white;
}

.btnRed:hover {
    color: tomato;
    background: white;
}

.btnGreen:hover {
    color: limegreen;
    background: white;
}

.btnDisabled {
    color: white;
    background: var(--light-gray2-color);
    border: 1px solid var(--light-gray2-color);
    cursor: not-allowed;
}

.modifier {
    display: flex;
}

.modifier:not(:last-child) {
    margin-bottom: 1.5rem;
}

.modifier div {
    display: flex;
    flex-direction: column;
}

.modifier div:not(:last-child) {
    margin-right: 1rem;
}

.modifier input {
    width: 100%;
}

input:disabled {
    cursor: not-allowed;
    background: var(--light-gray-color);
}

.row {
    margin: 2rem 0;
}

.col2 {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.dRow {
    display: flex;
    align-items: center;
}