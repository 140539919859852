.btn {
    display: inline-block;
    padding: 7px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
    border-radius: 5px;
    color: white;
    outline: none;
    border: 1px solid var(--accent-alt-color);
    transition: .3s;
    -webkit-transition: .3s;
    cursor: pointer;
    background-color: var(--accent-alt-color);
}

.btnImage {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    -webkit-transition: .3s;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    text-decoration: none;
    border: none;
    padding: .25rem .5rem 0;
}

.btnImage img {
    height: 1.5rem;
    width: auto;
    margin: 0 auto;
    padding: 0;
}

.btn:hover {
    color: var(--accent-alt-color);
    background: white;
}

.btnDisabled {
    color: white;
    background: var(--light-gray2-color);
    border: 1px solid var(--light-gray2-color);
    cursor: not-allowed;
}