* {
    box-sizing: border-box;
}

.wf-active body, .wf-active button, .wf-active input {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
}

:root {
    --light-gray-color: rgb(250, 250, 250);
    --light-gray2-color: rgb(236, 236, 236);
    --text-color: rgb(28, 28, 28);
    --muted-text-color: rgb(125, 125, 125);
    --accent-color: rgb(218, 255, 2);
    --accent-alt-color: rgb(178, 88, 241);
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 100vh;
    color: var(--text-color);
}

#root {
    width: 100%;
    height: inherit;
}

.image {
    position: relative;
    cursor: grab;
}

.image img {
    width: 100%;
    height: auto;
    opacity: 1;
}

.image.selected img {
    opacity: .5;
}

.image button {
    position: absolute;
    top: -1rem;
    right: -1rem;
    outline: none;
    cursor: pointer;
    background-color: red;
    border: 1px solid red;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image button img {
    width: 1.25rem;
    height: auto;
}

.wrapperClassName {
    border: 1px solid lightgrey;
}

.lds-dual-ring {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: .75rem;
    height: .75rem;
    margin: 0;
    border-radius: 50%;
    border: 3px solid;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
