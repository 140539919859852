.table {
    width: 100%;
    caption-side: bottom;
    border-collapse: collapse;
    -webkit-border-horizontal-spacing: 2px;
    -webkit-border-vertical-spacing: 2px;
}

.table>:not(caption)>*>* {
    padding: .5rem .5rem;
}

.table>tbody>tr:nth-child(odd)>td,
.table>tbody>tr:nth-child(odd)>th {
    background-color: rgb(247, 247, 247);
}

.table>thead {
    vertical-align: bottom;
}

tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

thead {
    display: table-header-group;
}

tr.isDeleted {
    opacity: .2;
}

tr {
    cursor: pointer;
}

.tableHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;
}

.tableHeader > *:not(:last-child) {
    margin-right: 1rem;
}

.tableHeader_search {
    width: 100%;
    display: flex;
    flex: 1 1 auto;
}

.tableHeader_buttons {
    display: flex;
    flex: 0 0 auto;
}

.pagination {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    flex: 1 1 auto;
    margin: 0;
}

.pages {

}

li.page {
    counter-increment: pagination;
}
.page:hover a {
    color: #fdfdfd;
    background-color: #1d1f20;
    border: solid 1px #1d1f20;
}
.page.active a {
    color: #fdfdfd;
    background-color: #1d1f20;
    border: solid 1px #1d1f20;
}
.page:first-child {
    float: left;
}
.page:first-child a:after {
    content: "<<";
}
.page:nth-child(2) {
    counter-reset: pagination;
}
.page:last-child {
    float: right;
}
.page:last-child a:after {
    content: ">>";
}
.page a {
    border: solid 1px #d7d7d7;
    border-radius: 0.2rem;
    color: #7d7d7d;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    padding: 0.5rem 0.9rem;
}
.page a:after {
    content: " " counter(pagination) " ";
}

ul, li {
    list-style: none;
    display: inline;
    padding-left: 0;
}