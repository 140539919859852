.aside {
    grid-area: aside;
    display: grid;
    grid-template-rows: 3rem 1fr 3rem;
    gap: 0;
    grid-template-areas:
    "aside__logo"
    "aside__content"
    "aside__footer";
    width: 100%;
    height: 100%;
    background-color: var(--light-gray-color);
}

.aside__logo {
    grid-area: aside__logo;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aside__content {
    grid-area: aside__content;
    width: 100%;
    list-style: none;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
}

.aside__footer {
    grid-area: aside__footer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aside__content a {
    padding: .75rem 1rem;
    margin: .25rem 0;
}

.nav__link {
    background: transparent;
    border-radius: 6px;
    color: var(--text-color);
    text-decoration: none;
}

.nav__link:hover {
    background: var(--light-gray2-color);
}

.nav__link__active {
    background: var(--light-gray2-color);
}