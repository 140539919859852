.header {
    border-bottom: 1px solid var(--light-gray2-color);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
}

.header__h1 {
    font-size: 2rem;
    padding: 0;
    margin: 0;
}

.header__links {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
}

.header__links > li:not(:last-child) {
    margin-right: 1rem;
}

.header__link {
    color: var(--muted-text-color);
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: bold;
}

.header__link__active {
    color: var(--text-color);
    border-bottom: 1px solid var(--text-color);
}