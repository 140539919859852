.Preview {
    width: 10rem;
    height: auto;
}

.Slide {
    width: 8rem;
    height: auto;
}

.Slides {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.SlidesItem {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    border: 2px solid rgb(55, 155, 255);
    border-radius: 10px;
    padding: .5rem;
}
.SlidesItemContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.SlidesItemAction {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    gap: 1rem;
}
.SlidesItemAction>div {
    display: flex;
    flex-direction: column;
    gap: .6rem;
}
.SlidesItemHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(192, 192, 192);
}
.SlidesItemHeader>div {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
}
.RemoveBtn {
    background-color: rgb(255, 92, 92);
    border: 0;
    cursor: pointer;
}
.BtnNav {
    background-color: transparent;
    border: 0;
}
.BtnNav>img {
    width: 1.5rem;
    height: auto;
    cursor: pointer;
}
.BtnNav:disabled>img {
    filter: grayscale(1);
    cursor: not-allowed;
}

.SlideAdd {
    width: 100%;
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    border: 2px dashed rgb(55, 155, 255);
    cursor: pointer;
}